import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    // { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    // { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    // { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    // { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    // { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    // { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
    // { title: 'Inicio', url: '/', icon: 'arrow-forward' },
    { title: 'Lecturas', url: '/lecturas', icon: 'calendar' },
    { title: 'Beneficios', url: '/beneficios', icon: 'checkmark-circle' },
    { title: 'Presupuesto', url: '/presupuesto', icon: 'clipboard' },
    { title: 'Mis Datos', url: '/contacto', icon: 'person' },
    { title: 'Financiamiento', url: '/financiamiento', icon: 'card' },
    // { title: 'Prospectos', url: '/prospectos', icon: 'grid' },
  ];
  // public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  public localStorage;

  constructor() {
    this.localStorage = localStorage;
  }


}
