import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/inicio/inicio.module').then(m => m.InicioPageModule)
  },
  //{
  //  path: '',
  //  redirectTo: 'folder/Inbox',
  //  pathMatch: 'full'
  //},
  //{
  //  path: 'folder/:id',
  //  loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  //},
  // {
  //   path: 'prueba',
  //   loadChildren: () => import('./prueba/prueba.module').then( m => m.PruebaPageModule)
  // },
  {
    path: 'lecturas',
    loadChildren: () => import('./pages/lecturas/lecturas.module').then( m => m.LecturasPageModule)
  },
  {
    path: 'contacto',
    loadChildren: () => import('./pages/contacto/contacto.module').then( m => m.ContactoPageModule)
  },
  {
    path: 'presupuesto',
    loadChildren: () => import('./pages/presupuesto/presupuesto.module').then( m => m.PresupuestoPageModule)
  },
  {
    path: 'beneficios',
    loadChildren: () => import('./pages/beneficios/beneficios.module').then( m => m.BeneficiosPageModule)
  },
  {
    path: 'inicio',
    loadChildren: () => import('./pages/inicio/inicio.module').then( m => m.InicioPageModule)
  },
  {
    path: 'prospectos',
    loadChildren: () => import('./pages/ayuda/ayuda.module').then( m => m.AyudaPageModule)
  },
  {
    path: 'financiamiento',
    loadChildren: () => import('./pages/financiamiento/financiamiento.module').then( m => m.FinanciamientoPageModule)
  },
  {
    path: 'privacidadaviso',
    loadChildren: () => import('./pages/privacidadaviso/privacidadaviso.module').then( m => m.PrivacidadavisoPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
